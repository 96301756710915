import React, { Component } from "react";
import { Grid, Icon } from "@material-ui/core";
import { getProcessedDynamic } from "../../../General/commonFunctions";
// import store from "../../../AppRedux/store";
import { ownStore } from "../../../AppOwnState/ownState";

class FactsDesignerDummy extends Component{
    constructor(props) {
        super(props);
        
     }
 

    render(){
        return(
            <div {...this.props} style={
                    {
                        backgroundColor: "#ffeb3b", 
                        color: "grey", 
                        padding: "5px",
                        display: "flex", 
                        alignItems : "center"
                } 
            }>
                <Icon style={{pointerEvents:"none"}}>data_object</Icon>
                 {this.props.type}
            </div>
        )
    }
}

export default FactsDesignerDummy; 