import HttpService from "./httpService";
import { authStorageKey, userDetailsStorageKey, userMenuStorageKey, apiRoot } from "../General/globals";
import StorageService from './storageService';
import ServiceBase from './_serviceBase'
import { authLogout } from "../AppOwnState";
import { ownStore } from "../AppOwnState/ownState";
// import Axios from "axios";

class AuthService extends ServiceBase {

  constructor(authPath, authBody) {
    super();
    this.authPath = authPath;
    this.authBody = authBody;
  }

  async login(client_id, client_secret, remember_me) {

    let requestBody = `grant_type=${this.authBody.grant_type}&hash_method=${this.authBody.hash_method}&job_id=${this.authBody.job_id}&client_id=${client_id}&client_secret=${client_secret}`;

    let headers = {};
    headers['Content-Type'] = 'application/x-www-form-urlencoded';

    let json = await new HttpService().postServer(apiRoot, this.authPath, headers, requestBody);
    let fullURL = apiRoot + this.authPath; 
    json =  await this.manageOfflineData(undefined, { fullURL, requestBody}, json);

    if (json && json.access_token) {
      let stService = new StorageService();
      stService.setStatePersistance(remember_me);
      stService.setJsonValue(authStorageKey,
        {
          at: json.access_token,
          tt: json.token_type,
          ei: json.expires_in
        });
    }
    else {
      console.log('Login Failed');
    }
  }

  async logout() {
    let currentToken = new StorageService().getJsonValue(authStorageKey);
    if (currentToken) {
      await this.loadFromServer(this.authBody.job_id, 'PWA.UserLogout', currentToken.at, { 'info': currentToken.at });
    }
    let stService = new StorageService();
    stService.clearStorage(authStorageKey);
    stService.clearStorage(userDetailsStorageKey);
    stService.clearStorage(userMenuStorageKey);
    // SetCreateDataSet({ dset: "pageinfo" }, null);
    ownStore.dispatch(authLogout()); //Redux


  }


  async verifyLogin() {
    let currentToken = new StorageService().getJsonValue(authStorageKey);
    if (!currentToken) return null;
    let json = await this.loadFromServer(this.authBody.job_id, 'PWA.VerifyAuth', currentToken.at, { 'info': currentToken.at });
    if (json && json.length > 0 && json[0].status === "true") return true;
    return false;
  }

  async loadUserDetails() {
    let currentToken = new StorageService().getJsonValue(authStorageKey);
    if (!currentToken) return null;
    return await this.loadFromServer(this.authBody.job_id, 'PWA.GetUserDetails', currentToken.at, { 'info': currentToken.at });
  }

  async loadUserMenu(userRole) {
    let currentToken = new StorageService().getJsonValue(authStorageKey);
    if (!currentToken) return null;
    return await this.loadFromServer(this.authBody.job_id, 'PWA.GetUserMenu', currentToken.at, { 'info': userRole });
  }

}

export default AuthService; 